import { message, Spin, Typography } from 'antd'
import _ from 'lodash'
import { FC, useCallback, useState } from 'react'
import styled from 'styled-components'

import useFetchDishes from '@ic-api/menu/useFetchDishes'
import Dish from '@models/dish'
import Layout from '@root/components/Layout'
import CategoryView from '@root/components/menu/CategoryView'
import StyledContainer from '@root/ic-ui-react/StyledContainer'

interface ListItemProps {
  active?: boolean
}
const ListItem = styled.button<ListItemProps>`
  display: inline-block;
  margin: 0.75rem;
  color: #1d39c4;
  background: ${(props) => (props.active ? 'white' : '#f0f5ff')};
  border: 0.2px solid #adc6ff;
  padding: 0.5rem 0.75rem;
  width: auto;
  flex: none;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`

const CateringMenu: FC = () => {
  const { loading, dishes, error } = useFetchDishes()

  return loading ? (
    <Spin size="large" tip="LOADING..." style={{ display: 'block', marginLeft: 'auto' }} />
  ) : (
    <>
      {error ? (
        message.error(error)
      ) : (
        <>{dishes && dishes.length > 0 && <MenuDisplay dishes={dishes} />}</>
      )}
    </>
  )
}

const MenuDisplay: FC<{ dishes: Dish[] }> = ({ dishes }) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>('Packages')

  const handleChangeSubcategory = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) =>
      setSelectedSubcategory(e.currentTarget.dataset.subcategory || 'Packages'),
    []
  )

  return (
    <StyledContainer fluid>
      <Layout>
        <StyledContainer fluid relax>
          <Typography.Title>CATERING MENU</Typography.Title>
          <Typography.Paragraph>
            Please call <Typography.Link href="tel:5624240446">(562) 424-0446 </Typography.Link>
            to order. Packages are for 10 or more people
          </Typography.Paragraph>
        </StyledContainer>

        <StyledContainer fluid>
          {_.uniqBy(_.filter(dishes, ['category', 'Catering']), 'subcategory').map((dish: Dish) => (
            <ListItem
              key={dish.id}
              data-subcategory={dish.subcategory}
              onClick={handleChangeSubcategory}
              active={selectedSubcategory === dish.subcategory}
            >
              {dish.subcategory}
            </ListItem>
          ))}
        </StyledContainer>

        <CategoryView
          dishes={_.filter(_.filter(dishes, ['category', 'Catering']), [
            'subcategory',
            selectedSubcategory,
          ])}
        />
      </Layout>
    </StyledContainer>
  )
}

export default CateringMenu
